import React, { useState } from "react";
import Spinner from "../../components/Spinner";
import AuthAlert from "../../components/AuthAlert";
import { handleResetPassword } from "../../config/database/authentication";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialError, setIniatialError] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const isEmailValid = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const onResetPassword = async () => {
    setIniatialError(false);
    await handleResetPassword({ email, setError, setLoading, setSuccess });
  };

  const onWrong = () => {
    setIniatialError(true);
  };

  return (
    <div className="h-screen px-[40px] bg-background">
      <div className="h-full">
        {/*  Left column container with background */}
        <div className="md:g-6 flex h-full items-center justify-center ">
          {/*  Right column container  */}
          <div className="mt-[20px] md:mt-0 border-[2px] rounded-2x p-[20px] py-[30px] mb-auto w-full md:mb-12 md:w-8/12 lg:w-4/12 xl:w-4/12 bg-white shadow-user">
            {error && <AuthAlert errorMessage={error} />}
            <form>
              {/* Email input  */}
              <div className="mb-8">
                <h3 className="text-[16px] font-poppins font-[400] leading-[18px] mb-1">
                  Email*
                </h3>
                <input
                  type="email"
                  className="peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {(email.length > 1 && !isEmailValid()) || initialError ? (
                  <div className="flex w-full mb-[15px]">
                    <p className="text-[red] text-start font-poppins text-[12px] sm:text-[14px]">
                      *Please enter a valid email
                    </p>
                  </div>
                ) : null}
              </div>

              {/* Sign Up button */}
              <div className="text-center lg:text-left">
                <button
                  type="button"
                  className="flex justify-center items-center font-poppins font-[400] w-full rounded bg-theme_three px-7 pb-2.5 pt-3 text-sm uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={isEmailValid() ? onResetPassword : onWrong}
                >
                  {loading ? (
                    <Spinner spinnerStyle={"border-white w-5 h-5"} />
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </div>
            </form>
            <div>
              {success && (
                <div className="flex border-green-800 border-[3px] text-center mt-6 justify-center py-[5px] font-poppins font-[500] text-green-800">
                  Password reset email sent! Please check your inbox.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
