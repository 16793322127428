// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "isMicOn",
  initialState: {
    isMicOn: "true",
  },
  reducers: {
    setIsMicOn: (state, action) => {
      state.isMicOn = action.payload;
    },
  },
});

export const { setIsMicOn } = counterSlice.actions;

export default counterSlice.reducer;

export const getIsMicOn = (state) => state.isMicOn.isMicOn;
