import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { handleSignUp } from "../../config/database/authentication";
import Spinner from "../../components/Spinner";
import AuthAlert from "../../components/AuthAlert";

const SignUp = () => {
  const [email, setEmail] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialError, setInitialError] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const isEmailValid = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email);
  };

  const checkPassword = () => {
    const isMatch = password.match(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
    )
      ? true
      : false;
    return isMatch;
  };

  const onSignUp = async () => {
    setInitialError(false);
    await handleSignUp({ email, password, navigate, setLoading, setError });
  };

  const onWrong = () => {
    setInitialError(true);
  };

  useEffect(() => {
    if (password || email || username) {
      setInitialError(false);
    }
  }, [email, password, username]);

  return (
    <div className='h-screen px-[40px] bg-background'>
      <div className='h-full'>
        {/*  Left column container with background */}
        <div className='md:g-6 flex flex-row-reverse h-full flex-wrap items-center justify-center lg:justify-between '>
          <div className='shrink-1 md:mb-12 grow-0 basis-auto  md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12 bg-theme_three rounded-2x shadow-lg'>
            <img
              src='https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp'
              className='w-full'
              alt='sign up'
            />
          </div>

          {/*  Right column container  */}
          <div className='mt-[10px] md:mt-0 mb-auto w-full md:mb-12 md:w-8/12 lg:w-5/12 xl:w-5/12'>
            {error && <AuthAlert errorMessage={error} />}
            <form>
              {/* Email input  */}
              <div className='mb-4'>
                <h3 className='text-[16px] font-poppins font-[400] leading-[18px] mb-1'>
                  Email*
                </h3>
                <input
                  type='email'
                  className='peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three'
                  placeholder='Enter your email'
                  onChange={(e) => setEmail(e.target.value)}
                />
                {(email.length > 1 && !isEmailValid()) || initialError ? (
                  <div className='flex w-full mb-[15px]'>
                    <p className='text-[red] text-start font-poppins text-[12px] sm:text-[14px]'>
                      *Please enter a valid email
                    </p>
                  </div>
                ) : null}
              </div>

              {/* Username input */}
              {/* <div className='relative mb-4' data-te-input-wrapper-init>
                <h3 className='text-[16px] font-poppins font-[400] leading-[18px] mb-1'>
                  Username*
                </h3>
                <input
                  type='text'
                  className='peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three'
                  placeholder='Enter your username'
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div> */}

              {/* Password input */}
              <div className='relative mb-6' data-te-input-wrapper-init>
                <h3 className='text-[16px] font-poppins font-[400] leading-[18px] mb-1'>
                  Password*
                </h3>
                <input
                  type='password'
                  className='peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three'
                  placeholder='Enter your password'
                  onChange={(e) => setPassword(e.target.value)}
                />
                {(password.length > 1 && !checkPassword()) || initialError ? (
                  <div className='flex w-full mb-[15px]'>
                    <p className='flex flex-wrap text-[red] text-start font-poppins text-[12px] sm:text-[14px]'>
                      *Password must be at least 8 characters long and include
                      at least one uppercase letter, one lowercase letter, one
                      number, and one symbol (!, @, #, $, %, ^, &, *).
                    </p>
                  </div>
                ) : null}
              </div>

              {/* Sign Up button */}
              <div className='text-center lg:text-left'>
                <button
                  type='button'
                  className='flex justify-center items-center font-poppins font-[400] w-full rounded bg-theme_three px-7 pb-2.5 pt-3 text-sm uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]'
                  data-te-ripple-init
                  data-te-ripple-color='light'
                  onClick={
                    checkPassword() && isEmailValid() ? onSignUp : onWrong
                  }>
                  {loading ? (
                    <Spinner spinnerStyle={"border-white w-5 h-5"} />
                  ) : (
                    "Sign Up"
                  )}
                </button>

                {/*  Register link  */}
                <p className='mb-0 mt-4 pt-1 text-[18px] font-poppins font-[400] text-center'>
                  Already have an account?{" "}
                  <Link
                    to={"/"}
                    className='text-theme_three transition duration-150 ease-in-out hover:text-theme_five focus:text-theme_five active:text-theme_five'>
                    Log In
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
