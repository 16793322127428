import React from "react";

const Confirmation = ({ showModal, setShowModal, onConfirm }) => {
  return (
    <>
      {showModal ? (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-[320px] my-6 mx-auto max-w-3xl'>
              {/*content*/}
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                {/*header*/}
                <div className='flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-3xl font-semibold text-center'>
                    Confirmation
                  </h3>
                </div>
                {/*body*/}
                <div className='relative p-4 flex-auto'>
                  <p className='my-4 text-slate-500 text-lg text-center leading-relaxed'>
                    Are you sure you want to log out?
                  </p>
                </div>
                {/*footer*/}
                <div className='flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setShowModal(false)}>
                    Close
                  </button>
                  <button
                    className='bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={onConfirm}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      ) : null}
    </>
  );
};

export default Confirmation;
