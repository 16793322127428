import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import {
  addSubmissionInForms,
  getDataFromAssistantId,
  getDataFromUrl,
} from "../../config/database/forms";
import { getChatConfigs } from "../../config/database/chat";
import {
  createAssistantThread,
  getAnswerFromThreads,
  getChatAnswer,
} from "../../api/chat";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { store } from "../../store";
import { setChat, setForm } from "../../store/apps/chat";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  MenuItem,
  Paper,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ThemeProvider from "../../theme";
import { error as errorColor } from "../../theme/palette";
import { grey } from "../../theme/palette";
import { alpha } from "@mui/material/styles";
import { getAssistantAppearance } from "../../config/database/appearance";
import { parseColor } from "../../util/utils";

const Forms = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [formInfo, setFormInfo] = useState();
  const [chatConfigData, setChatConfigData] = useState();
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(false);
  const [assistantId, setAssistantId] = useState("");
  const [assistantData, setAssistantData] = useState([]);
  const [threadId, setThreadId] = useState("");
  const [appearance, setAppearance] = useState(null);

  const defaultValues = formInfo?.fieldLabels.reduce((acc, field) => {
    acc[field.labelAlias] = "";
    return acc;
  }, {});

  const createDynamicSchema = (formFields) => {
    const schemaFields = {};

    formFields
      .filter((field) => field.type !== "description box")
      .forEach((field) => {
        schemaFields[field.labelAlias] = yup
          .string()
          .required(`${field.label} is a required field`);
      });

    return yup.object().shape(schemaFields);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(
      createDynamicSchema(
        (formInfo && formInfo.fieldLabels.length > 0 && formInfo.fieldLabels) ||
          []
      )
    ),
  });

  const onSubmit = async (data) => {
    setError(false);
    const typesToRemove = new Set(
      formInfo.fieldLabels
        .filter((field) => field.type === "description box")
        .map((field) => field.label || field.labelAlias)
    );
    const filteredData = Object.keys(data).reduce((acc, key) => {
      if (!typesToRemove.has(key)) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    setLoading(true);
    const replacedStory = formInfo?.story.replace(/{{(.*?)}}/g, (match) => {
      return data[match.replace(/[{}]/g, "")];
    });

    const userMessage = {
      role: "user",
      content: replacedStory.trim(),
    };
    const newMessages = [...messages, userMessage];
    const relevantMessages = newMessages.slice(-7);

    try {
      let response;
      if (
        assistantId &&
        threadId &&
        formInfo?.redirectionUrl === "assistant" &&
        assistantData[0]?.url
      ) {
        response = await getAnswerFromThreads({
          messages: replacedStory.trim(),
          assistantId,
          threadId,
        });
      } else {
        const newChatConfig = formInfo?.chatModel
          ? {
              ...chatConfigData,
              model: formInfo.chatModel,
            }
          : chatConfigData;
        response = await getChatAnswer({
          messages: relevantMessages,
          // ...chatConfigData,
          ...newChatConfig,
        });
      }
      const assistantReply =
        assistantId &&
        threadId &&
        formInfo?.redirectionUrl === "assistant" &&
        assistantData[0]?.url
          ? {
              role: "assistant",
              content: response.response,
              assistantThreadId: threadId,
            }
          : {
              role: "assistant",
              content: response.choices[0].message.content,
            };
      const updatedMessages = [...relevantMessages, assistantReply];

      setMessages(updatedMessages);
      store.dispatch(setChat(updatedMessages));
      store.dispatch(setForm(formInfo));
      await addSubmissionInForms(
        formInfo?.id,
        filteredData,
        updatedMessages[0]?.content
      );
      switch (formInfo?.redirectionUrl) {
        case "chat":
          navigate("/");
          break;
        case "choice":
          navigate("/choice");
          break;
        case "form-rss":
          navigate("/form-rss");
          break;
        case "voice-assistant":
          navigate("/voice-assistant");
          break;
        case "assistant":
          navigate(`/assistants/${assistantData[0]?.url}`);
          break;
        default:
          navigate("/");
          break;
      }
      setLoading(false);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkValidUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      setChatConfigData(data);
    };

    fetchChatConfig();
  }, []);

  useEffect(() => {
    if (assistantId && formInfo?.redirectionUrl === "assistant") {
      createThread();
    }
    //
    if (formInfo?.appearance && appearance === null) {
      fetchFormAppearance(formInfo?.appearance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistantId, formInfo]);

  useEffect(() => {
    assistantId && fetchAssistantData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistantId]);

  const fetchFormAppearance = async (appearanceId) => {
    const appearanceData = await getAssistantAppearance(appearanceId);
    setAppearance(appearanceData);
  };

  const fetchAssistantData = async () => {
    const assistant = await getDataFromAssistantId(assistantId);
    setAssistantData(assistant);
  };

  const createThread = async () => {
    const thread = await createAssistantThread();
    setThreadId(thread?.id);
  };

  const checkValidUrl = async () => {
    setValidationLoading(true);
    const form = await getDataFromUrl(
      decodeURIComponent(window.location.href).split("/").pop()
    );
    if (form.length > 0) {
      setFormInfo(form[0]);
      setAssistantId(form[0]?.assistantId || "");
    } else {
      navigate("/invalidLink");
    }
    setValidationLoading(false);
  };

  if (validationLoading) {
    return <LoadingScreen />;
  }

  // const placeholderColor = alpha(grey[600], 0.55);

  return (
    <ThemeProvider
      bgColor={
        appearance && appearance?.backgroundColor
          ? appearance?.backgroundColor
          : ""
      }
      bgImage={
        appearance && appearance?.downloadUrl ? appearance?.downloadUrl : ""
      }
    >
      <Container
        sx={{
          py: 5,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {(formInfo?.totalSubmission >= formInfo?.maxSubmission ||
          formInfo?.status === "deleted") && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {formInfo?.totalSubmission >= formInfo?.maxSubmission
              ? "This form has exceeded its maximum submission limit."
              : "This form has been deleted and submissions are no longer available."}
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 4 }}>
            Something went wrong. Please, try again later.
          </Alert>
        )}

        <Card
          sx={{
            px: 4,
            py: 5,
            width: "100%",
            backgroundColor: `rgba(${parseColor(
              appearance?.formBackgroundColor || "#FFFFFF"
            )}, ${String((appearance?.formBackgroundOpacity || 100) / 100)})`,
          }}
          elevation={appearance?.formBackgroundShadow || 1}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mb={3}
          >
            <Typography
              sx={{ color: appearance?.formTitleColor || "#000000" }}
              variant="h4"
            >
              {formInfo?.title}
            </Typography>
          </Stack>

          <Box sx={{ mx: { xs: 0, sm: 5, md: 10, lg: 15, xl: 20 } }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {formInfo?.fieldLabels?.map((item, index) => (
                <Box key={index}>
                  {item.type === "description box" ? (
                    <>
                      <Box
                        sx={{
                          mt: 3,
                          mb: 0.5,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item?.label || item.labelAlias,
                        }}
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mt: 3,
                        mb: 0.5,
                        color: appearance?.labelColor || "#000000",
                      }}
                    >
                      {item?.label || item.labelAlias}
                    </Typography>
                  )}

                  <Controller
                    name={item.labelAlias}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        {item.type === "text box" && (
                          <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={"Enter " + item?.labelAlias}
                            name={item.labelAlias}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={!!errors[item.labelAlias]}
                            InputProps={{
                              style: {
                                background:
                                  appearance?.inputFieldColor || "#FFFFFF",
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor:
                                    appearance?.inputFieldBorderColor ||
                                    "#C4CDD5", // default border color
                                },
                                "&:hover fieldset": {
                                  borderColor:
                                    appearance?.inputFieldHoverBorderColor ||
                                    "#000000", // border color on hover
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    appearance?.inputFieldActiveBorderColor ||
                                    "#1877F2", // active (focused) border color
                                },
                                "& .MuiOutlinedInput-input::placeholder": {
                                  color:
                                    appearance?.placeholderColor || "#A8ACB0",
                                },
                                "& .MuiOutlinedInput-input": {
                                  color:
                                    appearance?.inputTextColor || "#000000",
                                },
                              },
                            }}
                          />
                        )}
                        {item.type === "text area" && (
                          <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={"Enter " + item?.labelAlias}
                            name={item.labelAlias}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={!!errors[item.labelAlias]}
                            InputProps={{
                              style: {
                                background:
                                  appearance?.inputFieldColor || "#FFFFFF",
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor:
                                    appearance?.inputFieldBorderColor ||
                                    "#C4CDD5", // default border color
                                },
                                "&:hover fieldset": {
                                  borderColor:
                                    appearance?.inputFieldHoverBorderColor ||
                                    "#000000", // border color on hover
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    appearance?.inputFieldActiveBorderColor ||
                                    "#1877F2", // active (focused) border color
                                },
                                "& .MuiOutlinedInput-input::placeholder": {
                                  color:
                                    appearance?.placeholderColor || "#A8ACB0",
                                },
                                "& .MuiOutlinedInput-input": {
                                  color:
                                    appearance?.inputTextColor || "#000000",
                                },
                              },
                            }}
                          />
                        )}
                        {item.type === "radio button" && (
                          <>
                            {item.options.map((itemOption, indexOption) => {
                              return (
                                <Box
                                  key={indexOption}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Radio
                                    id={itemOption}
                                    value={itemOption}
                                    checked={value === itemOption}
                                    onChange={(e) => {
                                      onChange(e);
                                      setValue(item.labelAlias, itemOption);
                                      clearErrors(item.labelAlias);
                                    }}
                                    name={item.labelAlias}
                                    color="primary"
                                    sx={{
                                      color:
                                        appearance?.inputFieldBorderColor ||
                                        "#C4CDD5", // custom color for the unchecked state
                                      "&.Mui-checked": {
                                        color:
                                          appearance?.inputFieldActiveBorderColor ||
                                          "#1877F2", // custom color for the checked state
                                      },
                                      "&:hover": {
                                        background: `rgba(${parseColor(
                                          appearance?.inputFieldActiveBorderColor ||
                                            "#1877F2"
                                        )}, ${String(8 / 100)})`,
                                        color:
                                          appearance?.inputFieldHoverBorderColor ||
                                          "#000000",
                                      },
                                      "&.Mui-checked:hover": {
                                        color:
                                          appearance?.inputFieldActiveBorderColor ||
                                          "#1877F2",
                                      },
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      color:
                                        appearance?.radioOptionTextColor ||
                                        "#000000",
                                    }}
                                  >
                                    {itemOption}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </>
                        )}

                        {item.type === "multiple selection" && (
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={item.options}
                            value={
                              value !== undefined && value.length > 0
                                ? value.split(",")
                                : []
                            }
                            onChange={(event, newValue) => {
                              onChange(newValue.join(","));
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  sx={{
                                    background:
                                      appearance?.multiSelectChipBackgroundColor ||
                                      "#FFFFFF",
                                    borderColor:
                                      appearance?.multiSelectChipBorderColor ||
                                      "#C4CDD5",
                                    color:
                                      appearance?.inputTextColor || "#000000",
                                    "& .MuiChip-deleteIcon": {
                                      color:
                                        appearance?.multiSelectChipCrossIconColor ||
                                        "#C6C8CB", // change this to your desired delete icon color
                                    },
                                  }}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                variant="outlined"
                                placeholder={"Select " + item?.labelAlias}
                                error={!!errors[item.labelAlias]}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    backgroundColor:
                                      appearance?.inputFieldColor || "#FFFFFF",
                                    "& fieldset": {
                                      borderColor:
                                        appearance?.inputFieldBorderColor ||
                                        "#C4CDD5", // default border color
                                    },
                                    "&:hover fieldset": {
                                      borderColor:
                                        appearance?.inputFieldHoverBorderColor ||
                                        "#000000", // border color on hover
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor:
                                        appearance?.inputFieldActiveBorderColor ||
                                        "#1877F2", // active (focused) border color
                                    },
                                    "& .MuiOutlinedInput-input::placeholder": {
                                      color:
                                        appearance?.placeholderColor ||
                                        "#A8ACB0",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      color:
                                        appearance?.inputTextColor || "#000000",
                                    },
                                  },
                                }}
                                {...params}
                              />
                            )}
                            PaperComponent={({ children }) => (
                              <Paper
                                sx={{
                                  boxShadow: `0 0 2px 0 ${alpha(
                                    grey[500],
                                    0.24
                                  )}, -20px 20px 40px -4px ${alpha(
                                    grey[500],
                                    0.24
                                  )}`,
                                }}
                                style={{
                                  backgroundColor:
                                    appearance?.dropDownListBackgroundColor ||
                                    "#FFFFFF", // option bgcolor
                                }}
                              >
                                {children}
                              </Paper>
                            )}
                            sx={{
                              "& .MuiAutocomplete-popupIndicator": {
                                color:
                                  appearance?.dropDownIconColor || "#637381", // change the arrow icon color here
                              },
                              "& .MuiAutocomplete-clearIndicator": {
                                color:
                                  appearance?.dropDownIconColor || "#637381", // change the clear icon color here
                              },
                            }}
                            renderOption={(props, option, { selected }) => (
                              <Box
                                component="li"
                                {...props}
                                sx={{
                                  // Default background color: blue for unselected, navy for selected.
                                  backgroundColor: selected
                                    ? appearance?.dropDownSelectedOptionBackgroundColor ||
                                      "#EDF4FE"
                                    : appearance?.dropDownListBackgroundColor ||
                                      "#FFFFFF",
                                  "&:hover": {
                                    backgroundColor: selected
                                      ? appearance?.dropDownSelectedOptionHoverBackgroundColor ||
                                        "#D1E4FC"
                                      : appearance?.dropDownListOptionHoverBackgroundColor ||
                                        "#C4CDD5",
                                  },
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: selected
                                      ? appearance?.dropDownSelectedOptionTextColor ||
                                        "#000000"
                                      : appearance?.dropDownListTextColor ||
                                        "#000000",
                                    "&:hover": {
                                      color: selected
                                        ? appearance?.dropDownSelectedOptionHoverTextColor ||
                                          "#000000"
                                        : appearance?.dropDownListOptionHoverTextColor ||
                                          "#000000",
                                    },
                                  }}
                                >
                                  {option}
                                </Typography>
                              </Box>
                            )}
                          />
                        )}

                        {item.type === "selection" && (
                          <>
                            {item.type === "selection" && (
                              <Select
                                id={item.labelAlias}
                                value={value || ""}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                displayEmpty
                                fullWidth
                                variant="outlined"
                                style={{
                                  minHeight: "40px",
                                }}
                                error={!!errors[item.labelAlias]}
                                sx={{
                                  "&.MuiOutlinedInput-root": {
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor:
                                        appearance?.inputFieldBorderColor ||
                                        "#C4CDD5", // default border color
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor:
                                          appearance?.inputFieldHoverBorderColor ||
                                          "#000000", // border color on hover
                                      },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor:
                                          appearance?.inputFieldActiveBorderColor ||
                                          "#1877F2", // active (focused) border color
                                      },
                                    "& .MuiOutlinedInput-input": {
                                      color:
                                        appearance?.inputTextColor || "#000000",
                                    },
                                  },
                                  "& .MuiSelect-select": {
                                    backgroundColor:
                                      appearance?.inputFieldColor || "#FFFFFF",
                                  },
                                  "& .MuiSelect-icon": {
                                    color:
                                      appearance?.dropDownIconColor ||
                                      "#637381", // Change this to your desired arrow color
                                  },
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      backgroundColor:
                                        appearance?.dropDownListBackgroundColor ||
                                        "#FFFFFF", // background color for the entire dropdown Paper
                                    },
                                  },
                                }}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return (
                                      <Typography
                                        sx={{
                                          color:
                                            appearance?.placeholderColor ||
                                            "#A8ACB0",
                                          fontWeight: 400,
                                        }}
                                      >{`Select ${item.labelAlias}`}</Typography>
                                    );
                                  }
                                  return selected;
                                }}
                              >
                                {item.options.map((itemOption, indexOption) => (
                                  <MenuItem
                                    key={indexOption}
                                    value={itemOption}
                                    sx={{
                                      backgroundColor:
                                        appearance?.dropDownListBackgroundColor ||
                                        "#FFFFFF", // default option background color
                                      color:
                                        appearance?.dropDownListTextColor ||
                                        "#000000", // default text color
                                      "&:hover": {
                                        backgroundColor:
                                          appearance?.dropDownListOptionHoverBackgroundColor ||
                                          "#C4CDD5", // background on hover
                                        color:
                                          appearance?.dropDownListOptionHoverTextColor ||
                                          "#000000", // text color on hover
                                      },
                                      "&.Mui-selected": {
                                        backgroundColor:
                                          appearance?.dropDownSelectedOptionBackgroundColor ||
                                          "#EDF4FE", // background when selected
                                        color:
                                          appearance?.dropDownSelectedOptionTextColor ||
                                          "#000000", // text color when selected
                                      },
                                      "&.Mui-selected:hover": {
                                        backgroundColor:
                                          appearance?.dropDownSelectedOptionHoverBackgroundColor ||
                                          "#D1E4FC", // background when selected and hovered
                                        color:
                                          appearance?.dropDownSelectedOptionHoverTextColor ||
                                          "#000000", // text color when selected and hovered
                                      },
                                    }}
                                  >
                                    {itemOption}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </>
                        )}

                        {errors[item.labelAlias] && (
                          <Typography
                            sx={{
                              color: errorColor.main,
                              fontWeight: 400,
                              fontSize: 14,
                            }}
                          >
                            {errors[item.labelAlias].message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
              ))}

              <Button
                type="submit"
                variant="contained"
                disabled={
                  formInfo?.totalSubmission >= formInfo?.maxSubmission ||
                  formInfo?.status === "deleted"
                }
                sx={{
                  width: 130,
                  height: 40,
                  mt: 5,
                  display: "flex",
                  mx: "auto",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 15,
                  background: appearance?.buttonColor || "#1877F2",
                  color: appearance?.buttonTextColor || "#FFFFFF",
                }}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </Box>
        </Card>
      </Container>
    </ThemeProvider>
  );
};

export default Forms;
