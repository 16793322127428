import React from "react";
import './style.css'

const LoadingScreen = () => {
  return (
    <div className="loader">
      <div className="justify-content-center jimu-primary-loading"></div>
    </div>
  );
};

export default LoadingScreen;
