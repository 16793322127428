import React from "react";

const Spinner = ({ spinnerStyle }) => {
  return (
    <div
      className={`${spinnerStyle} inline-block animate-spin rounded-full border-4 border-solid border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    ></div>
  );
};

export default Spinner;
