import React, { useEffect, useState } from "react";
import { getDataFromUrl } from "../../config/database/assistants";
import { Navigate } from "react-router-dom";
import Chat from "../Chat";
import VoiceAssistant from "../voiceAssistant";

const Assistants = () => {
  const [component, setComponent] = useState(null);

  useEffect(() => {
    checkValidUrl();
  }, []);

  const checkValidUrl = async () => {
    const pathname = decodeURIComponent(window.location.pathname);

    const urlParts = pathname.split("/").filter((part) => part);
    if (urlParts.length >= 2) {
      const route = urlParts[urlParts.length - 2]; // second last part
      const routeId = urlParts[urlParts.length - 1]; // last part

      if (route === "assistants") {
        const assistant = await getDataFromUrl(routeId);
        if (assistant.length > 0) {
          switch (assistant[0]?.redirectionUrl) {
            case "chat":
              setComponent(<Chat />);
              break;
            case "voice-assistant":
              setComponent(<VoiceAssistant />);
              break;
            default:
              setComponent(<Chat />);
              break;
          }
        } else {
          Navigate("/invalidLink");
        }
      }
    }
  };

  return component;
};

export default Assistants;
