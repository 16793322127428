import {
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

async function getDataFromFineTuneUrl(url) {
  try {
    const fineTunesCollection = collection(db, "fineTunings");
    const q = query(fineTunesCollection, where("url", "==", url));
    const querySnapshot = await getDocs(q);
    const data = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const docData = { id: doc.id, ...doc.data() };
        return docData;
      })
    );
    return data;
  } catch (error) {
    console.error("Error fetching data from URL: ", error.message);
    throw error;
  }
}

async function updateFineTuningData(fineTuningId, data) {
  try {
    const fineTuningRef = doc(db, "fineTunings", fineTuningId);
    await updateDoc(fineTuningRef, {
      ...data,
      status: "active",
      updatedAtAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error updating fineTuning data: ", error);
    throw error;
  }
}

export { getDataFromFineTuneUrl, updateFineTuningData };
