import React, { useState, useEffect } from "react";
import Terminal, { ColorMode } from "react-terminal-ui";
import "./formRss.css";
import styled from "styled-components";
import { fetchSpeech } from "../../api/chat";
import { getSpeechConfigs } from "../../config/database/chat";
import { getChat, getForm } from "../../store/apps/chat";
import { useSelector } from "react-redux";
import Typewriter from "./Typewriter";
import { Alert, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const StyledTerminalWrapper = styled.div`
  --font-size-base: 14px;
  --font-size-md: 15px;
  --font-size-lg: 17px;
  --font-size-xl: 18px;
  --font-size-xxl: 20px;

  --padding-base: 20px 20px 35px;
  --padding-md: 35px 20px 35px;
  --padding-lg: 40px 30px 35px;
  --padding-xl: 40px 35px 35px;
  --padding-xxl: 40px 40px 35px;

  .react-terminal-wrapper {
    font-family: poppins;
    width: 100vw;
    height: 100vh;
    font-size: var(--font-size-base);
    padding: var(--padding-base);
    @media (min-width: 576px) {
      font-size: var(--font-size-md);
      padding: var(--padding-md);
    }

    @media (min-width: 768px) {
      font-size: var(--font-size-lg);
      padding: var(--padding-lg);
    }

    @media (min-width: 992px) {
      font-size: var(--font-size-xl);
      padding: var(--padding-xl);
    }

    @media (min-width: 1200px) {
      font-size: var(--font-size-xxl);
      padding: var(--padding-xxl);
    }
  }

  .heading {
    font-weight: 700;
  }

  .description {
    font-weight: 400;
  }

  .messages {
    font-weight: 500;
  }

  .react-terminal-window-buttons {
    display: none;
  }

  .react-terminal-line {
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-wrap: wrap;

    @media (min-width: 576px) {
      padding: 0;
      margin: 0;
    }
  }

  .react-terminal::-webkit-scrollbar {
    display: none;
  }

  .react-terminal {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const createTerminalMessage = (messages, className) => {
  return (
    <div className={className}>
      <Typewriter texts={[messages]} speed={60} startDelay={150} />
    </div>
  );
};

const FormRss = () => {
  const navigate = useNavigate();
  const formResponse = useSelector(getChat);
  const form = useSelector(getForm);
  const [loading, setLoading] = useState(false);
  const [speechConfigData, setSpeechConfigData] = useState({});
  const [formResponseText, setFormResponseText] = useState("");

  useEffect(() => {
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      const newSpeechConfig =
        form?.speechModel && form?.speechVoice
          ? {
              ...configData,
              model: form.speechModel,
              voice: form.speechVoice,
            }
          : configData;
      setSpeechConfigData(newSpeechConfig);
      // setSpeechConfigData(configData);
    };

    fetchSpeechConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formResponse.length > 0 && Object.keys(speechConfigData).length > 0) {
      setLoading(true);
      speakMessage(formResponse[1].content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formResponse, speechConfigData]);

  const speakMessage = async (message) => {
    await fetchSpeech(
      { text: message, ...speechConfigData },
      handleChunkFetched
    );
  };

  const handleChunkFetched = () => {
    if (formResponse[1].content) {
      setFormResponseText(formResponse[1].content);
      setLoading(false);
    }
  };

  return (
    <div>
      <StyledTerminalWrapper>
        <Terminal colorMode={ColorMode.Dark} height="100%" prompt=">>">
          {loading &&
            formResponse.length > 0 &&
            createTerminalMessage("Loading...", "messages")}
          {!loading &&
            formResponse.length > 0 &&
            createTerminalMessage(formResponseText, "messages")}
          {formResponse.length === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                my: "auto",
              }}
            >
              <Alert severity="error" sx={{}}>
                {"This url is no longer available."}
              </Alert>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: 130,
                  height: 40,
                  mt: 5,
                  display: "flex",
                  mx: "auto",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 15,
                }}
                onClick={() => navigate("/")}
              >
                Back
              </Button>
            </Box>
          )}
        </Terminal>
      </StyledTerminalWrapper>
    </div>
  );
};

export default FormRss;
