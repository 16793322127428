/* eslint-disable no-unused-vars */
import axios from "axios";

const BASE_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL; //"http://127.0.0.1:5001/hem-tech/us-central1";
const BASE_URL_PROD = process.env.REACT_APP_BASE_URL_PROD; //"https://us-central1-hem-tech.cloudfunctions.net";

const BASE_URL = BASE_URL_PROD; //BASE_URL_PROD;

export const getRssFeed = async (data) => {
  try {
    const response = await axios.get(`${BASE_URL}/getFeedData`, {
      params: {
        url: data,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
