import { LuLogOut } from "react-icons/lu";
import { LuVolumeX } from "react-icons/lu";
import { LuVolume2 } from "react-icons/lu";
import { LuCircleUser } from "react-icons/lu";
import { store } from "./store";

const generateRoutes = (isMicOn) => [
  {
    title: "Logout",
    href: "#",
    Icon: LuLogOut,
  },
  {
    title: isMicOn === "true" ? "Speaking" : "Mute",
    href: isMicOn === "true" ? "false" : "true",
    Icon: isMicOn === "true" ? LuVolume2 : LuVolumeX,
  },
  {
    title: "My Profile",
    href: "#",
    Icon: LuCircleUser,
  },
];

let routes = generateRoutes(store.getState().isMicOn.isMicOn);

store.subscribe(() => {
  const isMicOn = store.getState().isMicOn.isMicOn;
  routes = generateRoutes(isMicOn);
});

export { routes };
