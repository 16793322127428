// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "chat",
  initialState: {
    chat: "",
    form: null,
  },
  reducers: {
    setChat: (state, action) => {
      state.chat = action.payload;
    },
    setForm: (state, action) => {
      state.form = action.payload;
    },
  },
});

export const { setChat, setForm } = counterSlice.actions;

export default counterSlice.reducer;

export const getChat = (state) => state.chat.chat;
export const getForm = (state) => state.chat.form;
