import React, { useState, useEffect, useRef } from "react";
import {
  getChatAnswer,
  fetchSpeech,
  getImageCreationAnswer,
  getChatAnswerWithFile,
  getImageVariationAnswer,
  createAssistantThread,
  getAnswerFromThreads,
  getAnswerFromFileThreads,
  getFineTuneJobRetrieve,
} from "../../api/chat";
import {
  getChatConfigs,
  getImageConfigs,
  getSpeechConfigs,
} from "../../config/database/chat";
import "./chat.css";
import ChatMessage from "../../components/ChatMessage";
import micGif from "../../assets/Images/mic.gif";
import { useSelector } from "react-redux";
import { getChat, getForm } from "../../store/apps/chat";
import {
  getAssistantAppearance,
  getUserAppearance,
} from "../../config/database/appearance";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getDataFromUrl } from "../../config/database/assistants";
import {
  getDataFromFineTuneUrl,
  updateFineTuningData,
} from "../../config/database/fineTuning";
import { useNavigate } from "react-router-dom";
import { TopBar } from "../../components/TopBar/TopBarHeader";
import { moderateScale, parseColor } from "../../util/utils";
import { store } from "../../store";
import { setAppearanceData } from "../../store/apps/appearance";
import { LuFilePlus2 } from "react-icons/lu";
import { LuMessageSquareText } from "react-icons/lu";
import { LuImage } from "react-icons/lu";
import { LuMic } from "react-icons/lu";
import { LuSend } from "react-icons/lu";
import { LuCircleArrowDown } from "react-icons/lu";
import { LuFileText } from "react-icons/lu";
import { LuX } from "react-icons/lu";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Chat = () => {
  const chat = useSelector(getChat);
  const form = useSelector(getForm);
  const isScreenMdOrLarger = window.innerWidth >= 768;
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);
  const [chatConfigData, setChatConfigData] = useState();
  const [divHeight, setDivHeight] = useState(0);
  const [speechConfigData, setSpeechConfigData] = useState({});
  const [speakingStates, setSpeakingStates] = useState({});
  const [isLongPress, setIsLongPress] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [isReSpeaking, setIsReSpeaking] = useState({});
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const [appearance, setAppearance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isImage, setIsImage] = useState(false);
  const [file, setFile] = useState(null);
  const [tempFile, setTempFile] = useState(null);

  const [imageConfigData, setImageConfigData] = useState();

  const [inputSpeechLanguage, setInputSpeechLanguage] = useState("no-NO");

  const [assistantData, setAssistantData] = useState("");
  const [assistantId, setAssistantId] = useState("");
  const [threadId, setThreadId] = useState("");

  const [fineTuneModel, setFineTuneModel] = useState("");
  const [fineTuneInstruction, setFineTuneInstruction] = useState("");

  const navigate = useNavigate();

  const validImageTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/gif",
  ];

  const backgroundStyle =
    appearance && appearance?.downloadUrl
      ? {
          background: `url(${appearance.downloadUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }
      : appearance &&
        appearance?.assistantChatBackgroundColor &&
        appearance?.userChatBackgroundColor
      ? {
          background: `linear-gradient(to right, ${appearance.assistantChatBackgroundColor}, ${appearance.userChatBackgroundColor})`,
        }
      : {
          background: "linear-gradient(to right, #26A69A, #2196F3",
        };

  const divRef = useRef(null);
  const messageContainerRef = useRef(null);
  const recognitionRef = useRef();
  const isScreenSmaller = window.innerWidth <= 450;

  useEffect(() => {
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      const newSpeechConfig =
        form?.speechModel && form?.speechVoice
          ? {
              ...configData,
              model: form.speechModel,
              voice: form.speechVoice,
            }
          : configData;
      setSpeechConfigData(newSpeechConfig);
      // setSpeechConfigData(configData);
    };

    fetchSpeechConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAppearanceData = async () => {
    const appearanceData = await getUserAppearance();
    setAppearance(appearanceData);
    store.dispatch(setAppearanceData(appearanceData));
    setLoading(false);
  };

  const fetchAssistantAppearanceData = async (appearanceId) => {
    const appearanceData = await getAssistantAppearance(appearanceId);
    setAppearance(appearanceData);
    store.dispatch(setAppearanceData(appearanceData));
    setLoading(false);
  };

  useEffect(() => {
    if (chat.length > 0 && Object.keys(speechConfigData).length > 0) {
      setMessages([...messages, chat[1]]);
      speakMessage(chat[1], 0);
      chat[1]?.assistantThreadId && setThreadId(chat[1]?.assistantThreadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, speechConfigData]);

  useEffect(() => {
    if (chat.length > 0 && Object.keys(speechConfigData).length > 0) {
      chat[1]?.assistantThreadId && setThreadId(chat[1]?.assistantThreadId);
    }
  }, [chat, speechConfigData, threadId]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      messageContainerRef.current;
    setShowScrollButton(scrollHeight - scrollTop > clientHeight);
  };

  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      const newChatConfig = form?.chatModel
        ? {
            ...data,
            model: form.chatModel,
          }
        : data;
      setChatConfigData(newChatConfig);
      // setChatConfigData(data);
    };

    fetchChatConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchImageConfig = async () => {
      const data = await getImageConfigs();
      setImageConfigData(data);
    };

    fetchImageConfig();
  }, []);

  useEffect(() => {
    if (messageContainerRef.current) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    checkValidUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textAreaRef = useRef(null);

  useEffect(() => {
    // Adjust the height based on the content
    const adjustHeight = () => {
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "auto"; // Reset the height to auto to calculate the new height
        textAreaRef.current.style.height = `${Math.min(
          textAreaRef.current.scrollHeight,
          75
        )}px`;
      }
    };

    adjustHeight();
  }, [inputValue]); // Adjust height whenever inputValue changes

  const checkValidUrl = async () => {
    const pathname = decodeURIComponent(window.location.pathname);

    const urlParts = pathname.split("/").filter((part) => part);
    if (urlParts.length >= 2) {
      const route = urlParts[urlParts.length - 2]; // second last part
      const routeId = urlParts[urlParts.length - 1]; // last part

      if (route === "assistants") {
        const assistant = await getDataFromUrl(routeId);
        if (assistant.length > 0) {
          setAssistantId(assistant[0]?.assistantId);
          setAssistantData(assistant[0]);
          setInputSpeechLanguage(assistant[0]?.inputSpeechLanguage || "no-NO");
          if (
            !assistant[0]?.prioritizeOtherAppearance &&
            assistant[0]?.appearance
          ) {
            fetchAssistantAppearanceData(assistant[0]?.appearance);
          } else {
            fetchAppearanceData();
          }
          if (threadId.length === 0) {
            const thread = await createAssistantThread();
            setThreadId(thread?.id);
          }
        } else {
          navigate("/invalidLink");
        }
      } else if (route === "fine-tuning") {
        fetchAppearanceData();
        const fineTuning = await getDataFromFineTuneUrl(routeId);

        if (fineTuning.length > 0) {
          if (fineTuning[0]?.fineTuningStatus === "in-progress") {
            const fineTuneJob = await getFineTuneJobRetrieve(
              fineTuning[0]?.fineTuningId
            );

            const updatedStatus =
              fineTuneJob.status === "succeeded" ||
              fineTuneJob.status === "failed"
                ? fineTuneJob.status
                : "in-progress";

            if (updatedStatus !== "in-progress") {
              await updateFineTuningData(fineTuning[0]?.id, {
                fineTuningStatus: updatedStatus,
                fineTuneModel: fineTuneJob.fine_tuned_model,
              });
              const currentFineTuneData = fineTuning[0];
              currentFineTuneData.fineTuningStatus = updatedStatus;
              currentFineTuneData.fineTuneModel = fineTuneJob.fine_tuned_model;
              setFineTuneModel(currentFineTuneData?.fineTuneModel);
              setFineTuneInstruction(currentFineTuneData?.instructions);
            } else {
              navigate("/invalidLink", {
                state: { message: "fine tuning in progress" },
              });
            }
          } else {
            setFineTuneModel(fineTuning[0]?.fineTuneModel);
            setFineTuneInstruction(fineTuning[0]?.instructions);
          }
        }
      } else {
        fetchAppearanceData();
      }
    } else {
      fetchAppearanceData();
    }
  };

  const scrollToBottom = () => {
    messageContainerRef.current.scrollTop =
      messageContainerRef.current.scrollHeight;
  };

  const sendMessage = async () => {
    if (!inputValue.trim()) return;

    if (
      messages.length > 1 &&
      messages[messages.length - 1].content === "Typing ..."
    ) {
      return;
    }

    if (file && file !== null) {
      setTempFile(file);
    }

    setIsReSpeaking({});
    setSpeakingStates({});

    const userMessage = {
      role: "user",
      content: inputValue.trim(),
      file: file && file !== null ? file : undefined,
    };
    // Include user message
    const newMessages = [...messages, userMessage];
    // typing indicator
    const typingMessage = {
      role: "assistant",
      content: isImage ? "Generating ..." : "Typing ...",
      typing: true,
    };

    setMessages([...newMessages, typingMessage]);
    setInputValue("");

    let relevantMessages = newMessages.slice(-7);
    const filteredArray = relevantMessages.filter(
      (item) => item.responseType !== "image"
    );
    relevantMessages = filteredArray;

    try {
      let response;
      if (assistantId && threadId) {
        if ((file && file !== null) || (tempFile && tempFile !== null)) {
          const formData = new FormData();
          formData.append("file", file && file !== null ? file : tempFile);
          Object.entries({
            messages: inputValue.trim(),
            assistantId,
            threadId,
          }).forEach(([key, value]) => {
            formData.append(key, JSON.stringify(value));
          });
          setFile(null);
          setTempFile(null);
          response = await getAnswerFromFileThreads(formData);
        } else {
          response = await getAnswerFromThreads({
            messages: inputValue.trim(),
            assistantId,
            threadId,
          });
        }
      } else if (isImage) {
        response = await getImageCreationAnswer({
          messages: inputValue.trim(),
          ...imageConfigData,
        });
      } else if ((file && file !== null) || (tempFile && tempFile !== null)) {
        const formData = new FormData();
        formData.append("file", file && file !== null ? file : tempFile);
        Object.entries({
          messages: relevantMessages,
          ...chatConfigData,
          fineTuneModel,
          fineTuneInstruction,
        }).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value));
        });
        setFile(null);
        response = await getChatAnswerWithFile(formData);
      } else {
        response = await getChatAnswer({
          messages: relevantMessages,
          ...chatConfigData,
          fineTuneModel,
          fineTuneInstruction,
        });
      }

      const assistantReply = {
        role: "assistant",
        content:
          assistantId && threadId
            ? response.response
            : isImage
            ? response.data
            : (file && file !== null) || (tempFile && tempFile !== null)
            ? response.answer
            : response.choices[0].message.content,
        responseType: isImage && "image",
      };
      const updatedMessages = [...newMessages, assistantReply];
      setMessages(updatedMessages);
      !isImage && speakMessage(assistantReply, updatedMessages.length - 1);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  const getVariationImage = async (imageUrl) => {
    const userMessage = {
      role: "user",
      content:
        imageConfigData?.numberOfImages > 1
          ? "Generating Image Variations"
          : "Generating Image Variation",
      file: file && file !== null ? file : undefined,
    };
    // Include user message
    const newMessages = [...messages, userMessage];
    // typing indicator
    const typingMessage = {
      role: "assistant",
      content: "Generating ...",
      typing: true,
    };

    setMessages([...newMessages, typingMessage]);
    setInputValue("");
    try {
      const response = await getImageVariationAnswer({
        imageUrl,
        ...imageConfigData,
      });
      const assistantReply = {
        role: "assistant",
        content: response.data,
        responseType: "image",
      };
      const updatedMessages = [...newMessages, assistantReply];
      setMessages(updatedMessages);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  const speakMessage = async (message, index) => {
    if (isMicOn) {
      setSpeakingStates((prev) => ({ ...prev, [index]: true }));
      await fetchSpeech(
        assistantData &&
          !assistantData?.defaultTtsConfig &&
          assistantData?.tts_model &&
          assistantData?.tts_speed &&
          assistantData?.tts_voice
          ? {
              text: message.content,
              model: assistantData?.tts_model,
              speed: assistantData?.tts_speed,
              voice: assistantData?.tts_voice,
            }
          : { text: message.content, ...speechConfigData }
      );
      setSpeakingStates((prev) => ({ ...prev, [index]: false }));
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };

    !loading && updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [loading]);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition not supported in this browser.");

      return;
    }
    const recognitionInstance = new window.webkitSpeechRecognition();
    recognitionInstance.continuous = true;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = inputSpeechLanguage; //"en-US";
    recognitionInstance.onresult = (event) => {
      let interim = "";
      let final = "";
      for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          final += transcript;
        } else {
          interim += transcript;
        }
      }
      setInputValue(final + interim);
    };

    recognitionInstance.onerror = (event) => {
      console.error("Speech recognition error", event);
    };
    setRecognition(recognitionInstance);
  }, [inputSpeechLanguage]);

  useEffect(() => {
    const checkIfTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };

    checkIfTouchDevice();
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsLongPress(true);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
    if (recognition) {
      recognition.start();
    }
  };

  const handleMouseUp = () => {
    setIsLongPress(false);
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mousemove", handleMouseMove);
    if (recognition) {
      recognition.stop();
    }
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 0) {
      handleMouseUp();
    }
  };

  const handleTouchStart = (e) => {
    if (isLongPress) {
      recognitionRef.current?.stop();
      setIsLongPress(false);
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = inputSpeechLanguage; //"en-US";
    recognitionRef.current.onstart = function () {
      setIsLongPress(true);
    };
    recognitionRef.current.onend = function () {
      setIsLongPress(false);
    };
    recognitionRef.current.onresult = async function (event) {
      let interim = "";
      let final = "";
      for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          final += transcript;
        } else {
          interim += transcript;
        }
      }

      if (window.navigator.platform.toLowerCase().includes("linux arm")) {
        setInputValue(event.results[event.results.length - 1][0].transcript);
      } else {
        setInputValue(final + interim);
      }
    };

    recognitionRef.current.start();
  };

  const handleChange = (event) => {
    setIsImage(event.target.value === "image" ? true : false);
  };

  const handleFileChange = (event) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (event?.target?.files[0]?.size > maxSizeInBytes) {
      alert("File size exceeds 5 MB. Please select another file.");
      setFile(null);
      event.target.value = "";
    } else {
      event.target.files[0] ? setFile(event.target.files[0]) : setFile(null);
    }
  };

  const renderSelectedValue = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isImage ? (
        <>
          <LuImage
            color={appearance?.inputSelectionImageColor || "#000000"}
            size={
              isScreenMdOrLarger
                ? moderateScale(25, appearance?.inputSelectionImageSize || 1)
                : moderateScale(20, appearance?.inputSelectionImageSize || 1)
            }
          />
          <p
            style={{
              color: appearance?.inputSelectionTextColor || "#000000",
              fontSize: moderateScale(
                16,
                appearance?.inputSelectionTextSize || 1
              ),
            }}
            className="ml-[5px] flex bg-transparent font-poppins font-[500]"
          >
            Image
          </p>
        </>
      ) : (
        <>
          <LuMessageSquareText
            color={appearance?.inputSelectionImageColor || "#000000"}
            size={
              isScreenMdOrLarger
                ? moderateScale(25, appearance?.inputSelectionImageSize || 1)
                : moderateScale(20, appearance?.inputSelectionImageSize || 1)
            }
          />
          <p
            style={{
              color: appearance?.inputSelectionTextColor || "#000000",
              fontSize: moderateScale(
                16,
                appearance?.inputSelectionTextSize || 1
              ),
            }}
            className="ml-[5px] flex bg-transparent font-poppins font-[500]"
          >
            Chat
          </p>
        </>
      )}
    </div>
  );

  const { height } = useWindowDimensions();

  const isMicOnState = useSelector((state) => state.isMicOn.isMicOn);

  useEffect(() => {
    setIsMicOn(isMicOnState === "true" ? true : false);
  }, [isMicOnState]);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="flex flex-col">
          <div ref={divRef}>
            <TopBar appearance={appearance} assistantData={assistantData} />
          </div>
          <div
            className="flex flex-col pb-2"
            style={{
              ...backgroundStyle,
              height: !isTouchDevice
                ? `calc(100vh - ${divHeight}px)`
                : height / 1.077,
            }}
          >
            <div className="flex flex-grow overflow-auto w-full p-[10px] justify-center items-center ">
              <div
                style={{
                  background: `rgba(${parseColor(
                    appearance?.chatBackgroundColor || "#c4d8e4"
                  )}, ${String(
                    (appearance?.chatBackgroundOpacity || 100) / 100
                  )})`,
                }}
                className="relative flex flex-col h-full w-full md:w-[70%] shadow-xl rounded-2x"
              >
                <div
                  className="chat-area m-[20px] gap-8 overflow-scroll scroll-smooth"
                  ref={messageContainerRef}
                  onScroll={handleScroll}
                >
                  {messages.map((message, index) => (
                    <React.Fragment key={index}>
                      <ChatMessage
                        message={message}
                        speechConfigData={speechConfigData}
                        isSpeaking={speakingStates[index] || false}
                        index={index}
                        setSpeakingStates={setSpeakingStates}
                        isReSpeaking={isReSpeaking[index] || false}
                        setIsReSpeaking={setIsReSpeaking}
                        isMicOn={isMicOn}
                        appearance={appearance}
                        getVariationImage={getVariationImage}
                      />
                    </React.Fragment>
                  ))}
                  {showScrollButton && (
                    <LuCircleArrowDown
                      color={"#000000"}
                      size={
                        isScreenMdOrLarger
                          ? moderateScale(25, 1)
                          : moderateScale(20, 1)
                      }
                      className="fixed bottom-[14%] right-[4%] md:right-[16%]"
                      style={{ backdropFilter: "blur(2px)" }}
                      onClick={scrollToBottom}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center items-center px-[10px]">
              <div
                style={{
                  background: `rgba(${parseColor(
                    appearance?.inputBackgroundColor || "#c4d8e4"
                  )}, ${String(
                    (appearance?.inputBackgroundOpacity || 100) / 100
                  )})`,
                  borderColor: appearance?.inputBorderColor || "#000000",
                }}
                className="w-full md:w-[70%] rounded-[15px] shadow-lg flex flex-col items-center overflow-hidden border-[1px] mt-0 mb-auto"
              >
                {file && file.name && file !== null && (
                  <div className=" ml-[10px] mr-auto mt-[10px] flex flex-row">
                    <div className="flex flex-row items-center rounded-[10px] shadow-lg border-[1px] border-black p-[5px]">
                      {validImageTypes.includes(file.type) ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Selected"
                          className="max-w-[75px] w-auto h-auto max-h-[75px] rounded-[5px]"
                        />
                      ) : (
                        <LuFileText
                          color={"#000000"}
                          size={
                            isScreenMdOrLarger
                              ? moderateScale(25, 1)
                              : moderateScale(20, 1)
                          }
                        />
                      )}
                      <p className="ml-[6px]">{file.name}</p>
                    </div>

                    <div
                      className="ml-[-10px] mt-[-10px]"
                      onClick={() => setFile(null)}
                    >
                      <LuX
                        color={"#000000"}
                        size={moderateScale(20, 1)}
                        className="mr-[12px] bg-white rounded-full"
                      />
                    </div>
                  </div>
                )}
                <style jsx>{`
                  textarea::placeholder {
                    color: ${appearance?.placeholderColor || "#000000"};
                    font-size: ${isScreenSmaller
                      ? `${
                          String(
                            moderateScale(
                              16,
                              appearance?.placeholderTextSize || 1
                            )
                          ) + "px" || "16px"
                        }`
                      : `${
                          String(
                            moderateScale(
                              16,
                              appearance?.placeholderTextSize || 1
                            )
                          ) + "px" || "16px"
                        }`};
                  }
                `}</style>
                <textarea
                  ref={textAreaRef}
                  value={inputValue}
                  style={{
                    color: appearance?.promptTextColor || "#000000",
                    fontSize: moderateScale(
                      16,
                      appearance?.promptTextSize || 1
                    ),
                    resize: "none",
                    maxHeight: "100px",
                  }}
                  className="bg-transparent font-poppins font-[500] w-full h-full mt-1 py-0 md:pl-[12px] px-[5px] md:px-[5px] focus:outline-none focus:shadow-outline"
                  autoComplete="off"
                  placeholder="Enter your question"
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      sendMessage();
                    }
                  }}
                  rows={1}
                />
                <div className="flex flex-row items-center w-[100%]">
                  {(assistantId === "" ||
                    (assistantData &&
                      assistantData?.type === "file_search")) && (
                    <div className="relative inline-block pl-[6px]">
                      <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        accept=".csv, .pdf, .docx, .txt, .html, .js, .json, .xlsx, .png, .jpeg, .jpg, .webp, .gif, .jsonl"
                        onChange={handleFileChange}
                      />
                      <label
                        htmlFor="fileInput"
                        className="cursor-pointer flex justify-center items-center"
                      >
                        <LuFilePlus2
                          color={appearance?.fileIconColor || "#000000"}
                          size={
                            isScreenMdOrLarger
                              ? moderateScale(25, appearance?.fileIconSize || 1)
                              : moderateScale(20, appearance?.fileIconSize || 1)
                          }
                        />
                      </label>
                    </div>
                  )}

                  {!assistantId && (
                    <div className="pl-[10px] md:pl-[20px]">
                      <FormControl variant="standard" sx={{ border: "none" }}>
                        <Select
                          value={isImage ? "image" : "chat"}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "Without label" }}
                          renderValue={renderSelectedValue}
                          sx={{
                            backgroundColor: "transparent",
                            "&:before": { borderBottom: "none" },
                            "&:after": { borderBottom: "none" },
                            mt: 0.4,
                          }}
                        >
                          <MenuItem value={"chat"} disableRipple>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LuMessageSquareText
                                color={
                                  appearance?.inputSelectionImageColor ||
                                  "#000000"
                                }
                                size={
                                  isScreenMdOrLarger
                                    ? moderateScale(
                                        25,
                                        appearance?.inputSelectionImageSize || 1
                                      )
                                    : moderateScale(
                                        20,
                                        appearance?.inputSelectionImageSize || 1
                                      )
                                }
                              />
                              <p
                                style={{
                                  color:
                                    appearance?.inputSelectionTextColor ||
                                    "#000000",
                                  fontSize: moderateScale(
                                    16,
                                    appearance?.inputSelectionTextSize || 1
                                  ),
                                }}
                                className="ml-[5px] flex bg-transparent font-poppins font-[500]"
                              >
                                Chat
                              </p>
                            </div>
                          </MenuItem>
                          <MenuItem value={"image"} disableRipple>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LuImage
                                color={
                                  appearance?.inputSelectionImageColor ||
                                  "#000000"
                                }
                                size={
                                  isScreenMdOrLarger
                                    ? moderateScale(
                                        25,
                                        appearance?.inputSelectionImageSize || 1
                                      )
                                    : moderateScale(
                                        20,
                                        appearance?.inputSelectionImageSize || 1
                                      )
                                }
                              />
                              <p
                                style={{
                                  color:
                                    appearance?.inputSelectionTextColor ||
                                    "#000000",
                                  fontSize: moderateScale(
                                    16,
                                    appearance?.inputSelectionTextSize || 1
                                  ),
                                }}
                                className="ml-[5px] flex bg-transparent font-poppins font-[500]"
                              >
                                Image
                              </p>
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  <div className="w-full" />
                  {!isTouchDevice ? (
                    <div
                      className="flex items-center justify-center mr-[5px] md:mr-[10px] cursor-pointer"
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                    >
                      {isLongPress ? (
                        <img
                          src={micGif}
                          alt="mic"
                          width={30}
                          draggable="false"
                        />
                      ) : (
                        //    <div style={{height:50,width:50,backgroundColor:'green', overflow:"hidden"}}>
                        // <div
                        //   style={{
                        //     width: "100%",
                        //     height: "100%",
                        //     backgroundColor: "#FF0000", // Exact hex color (red)
                        //     WebkitMaskImage: `url(${micGif})`,
                        //     maskImage: `url(${micGif})`,
                        //     backgroundSize: "contain",
                        //   }}
                        // ></div>
                        // </div>

                        // <MICROPHONE
                        //   style={{
                        //     height: isScreenMdOrLarger
                        //       ? moderateScale(30, appearance?.micIconSize || 1) //appearance?.micIconSize + 5 || 30
                        //       : moderateScale(25, appearance?.micIconSize || 1), //appearance?.micIconSize || 25,
                        //     width: isScreenMdOrLarger
                        //       ? moderateScale(30, appearance?.micIconSize || 1) //appearance?.micIconSize + 5 || 30
                        //       : moderateScale(25, appearance?.micIconSize || 1), //appearance?.micIconSize || 25,
                        //   }}
                        //   fill={appearance?.micIconColor || "#000000"}
                        //   //  className="md:w-[30px] md:h-[30px] w-[25px] h-[25px]"
                        // />
                        <LuMic
                          color={appearance?.micIconColor || "#000000"}
                          size={
                            isScreenMdOrLarger
                              ? moderateScale(25, appearance?.micIconSize || 1)
                              : moderateScale(20, appearance?.micIconSize || 1)
                          }
                        />
                      )}
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="flex items-center justify-center mr-[5px] md:mr-[10px] cursor-pointer"
                      onTouchStart={handleTouchStart}
                    >
                      {isLongPress ? (
                        <div
                          className="w-[30px] h-[30px]"
                          style={{
                            background: `url(${micGif}) no-repeat center`,
                            backgroundSize: "cover",
                          }}
                        />
                      ) : (
                        <LuMic
                          color={appearance?.micIconColor || "#000000"}
                          size={
                            isScreenMdOrLarger
                              ? moderateScale(25, appearance?.micIconSize || 1)
                              : moderateScale(20, appearance?.micIconSize || 1)
                          }
                        />
                      )}
                    </button>
                  )}
                  <div
                    className="flex items-center justify-center p-[8px] pr-[12px]"
                    onClick={sendMessage}
                  >
                    <LuSend
                      color={appearance?.sendIconColor || "#000000"}
                      size={
                        isScreenMdOrLarger
                          ? moderateScale(25, appearance?.sendIconSize || 1)
                          : moderateScale(20, appearance?.sendIconSize || 1)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
