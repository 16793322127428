import { useMemo } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles';

import { palette } from './palette';
import { shadows } from './shadows';
import { overrides } from './overrides';
import { typography } from './typography';
import { customShadows } from './custom-shadows';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children, bgColor, bgImage }) {
  const memoizedValue = useMemo(
    () => ({
      palette: palette(),
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    []
  );

  const theme = createTheme(memoizedValue);

  // theme.components = overrides(theme);
  theme.components = {
    ...overrides(theme),
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: bgImage ? `url(${bgImage})` : 'none',
          backgroundColor: bgColor?bgColor:memoizedValue.palette.background.default,//bgColor,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
  };

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
