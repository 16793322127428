import React from "react";
import { ERROR } from "../../assets/Images";

const AuthAlert = ({ errorMessage }) => {
  return (
    <div className="flex justify-center items-center py-[10px] mb-5 bg-red-200 rounded-md gap-2">
      <ERROR className="w-[25px] h-[25px] mx-[10px]" />
      <p className="text-[18px] font-poppins font-[400]">
        {errorMessage.message}
      </p>
    </div>
  );
};

export default AuthAlert;
