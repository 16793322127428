import React, { useEffect, useState } from "react";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

const Typewriter = ({
  texts,
  speed = 100,
  startDelay = 500,
  onWordTyped,
  setReadingAiResponse,
  typingText,
}) => {
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    if (!texts || texts.length === 0) return;

    let iIndex = 0;
    let iTextPos = 0;
    let isCompleted = false;

    // Set flag for reading AI response
    setReadingAiResponse &&
      typingText &&
      typingText !== "Typing..." &&
      setReadingAiResponse(true);

    const typewriter = () => {
      if (isCompleted) {
        clearTimeout(typewriterInterval);
        setReadingAiResponse &&
          typingText &&
          typingText !== "Typing..." &&
          setReadingAiResponse(false);
        return;
      }

      let sContents = "";
      let rowLength = Math.max(0, iIndex - 20);

      while (rowLength < iIndex) {
        sContents += texts[rowLength++] + "<br />";
      }

      // Update the current text
      setCurrentText(
        sContents +
          texts[iIndex].substring(0, iTextPos) +
          (iTextPos === texts[iIndex].length ? "" : "_")
      );

      if (iTextPos++ === texts[iIndex].length) {
        iTextPos = 0;
        iIndex++;

        if (iIndex !== texts.length) {
          setTimeout(typewriter, startDelay);
        } else {
          isCompleted = true;
          clearTimeout(typewriterInterval);
          setReadingAiResponse &&
            typingText &&
            typingText !== "Typing..." &&
            setReadingAiResponse(false);
        }
      } else {
        setTimeout(typewriter, speed);
      }

      onWordTyped && onWordTyped();
    };

    let typewriterInterval = setTimeout(typewriter, startDelay);

    return () => {
      clearTimeout(typewriterInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texts, speed, startDelay, onWordTyped]);

  const md = new MarkdownIt({
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return (
            '<pre class="hljs"><code>' +
            hljs.highlight(str, { language: lang, ignoreIllegals: true })
              .value +
            "</code></pre>"
          );
        } catch (__) {}
      }

      return (
        '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
      );
    },
  });

  const markdownLine = md.render(currentText);
  // return <div dangerouslySetInnerHTML={{ __html: currentText }} />;
  return (
    <div
      className={` md:mt-[5px]`}
      dangerouslySetInnerHTML={{ __html: markdownLine }}
    />
  );
};

export default Typewriter;
