import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  handleLinkSignIn,
  handleLogin,
} from "../../config/database/authentication";
import { useAuthState } from "react-firebase-hooks/auth";
import Spinner from "../../components/Spinner";
import AuthAlert from "../../components/AuthAlert";
import { auth } from "../../firebase";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import LoadingScreen from "../../components/Spinner/LoadingScreen";

const Login = () => {
  const [user] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [initialError, setInitialError] = useState({
    password: false,
    email: false,
  });

  const [error, setError] = useState(null);
  const { search } = useLocation();

  const [validationLoading, setValidationLoading] = useState(false);

  const [emailSendSuccess, setEmailSendSuccess] = useState(false);

  const navigate = useNavigate();

  const emailLinkValidation = async () => {
    try {
      setValidationLoading(true);
      if (!user) {
        // user is not signed in but the link is valid
        if (isSignInWithEmailLink(auth, window.location.href)) {
          // now in case user clicks the email link on a different device, we will ask for email confirmation
          let email = localStorage.getItem("emailForSignIn");
          if (!email) {
            email = window.prompt("Please provide your email");
          }
          // after that we will complete the login process

          await signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              // localStorage.removeItem("emailForSignIn");
              // console.log(result);
            })
            .catch((err) => {
              if (
                err.message === "Firebase: Error (auth/invalid-action-code)."
              ) {
                navigate("/invalidLink");
              }
            });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setValidationLoading(false);
    }
  };

  useEffect(() => {
    emailLinkValidation();
    // eslint-disable-next-line
  }, [user, search, navigate]);

  const isEmailValid = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailRegex.test(email);
  };

  const checkPassword = () => {
    const isMatch = password.match(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/
    )
      ? true
      : false;
    return isMatch;
  };

  const onLogin = async () => {
    setError(null);
    setInitialError({ password: false, email: false });
    setEmailSendSuccess(false);
    await handleLogin({
      navigate,
      email,
      password,
      setLoading,
      setError,
    });
  };

  const onLinkLogin = async () => {
    setError(null);
    setInitialError({ password: false, email: false });
    setEmailSendSuccess(false);
    await handleLinkSignIn({
      email,
      setLinkLoading,
      setEmailSendSuccess,
      setError,
    });
  };

  const onWrong = (type) => {
    if (type === "login" && !(password && email)) {
      setInitialError({ password: true, email: true });
    } else {
      setInitialError({ password: false, email: true });
    }
  };

  useEffect(() => {
    if (password || email) {
      setInitialError({ password: false, email: false });
    }
  }, [email, password]);

  if (validationLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className='h-screen px-[40px] bg-background'>
      <div className='h-full'>
        {/*  Left column container with background */}
        <div className='md:gap-6  flex h-full flex-wrap items-center justify-center lg:justify-between '>
          <div className='shrink-1 md:mb-12 grow-0 basis-auto  md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12 bg-theme_three shadow-lg rounded-2x'>
            <img
              src='https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp'
              className='w-full'
              alt='login'
            />
          </div>

          {/*  Right column container  */}
          <div className='mt-[10px] md:mt-0 mb-auto md:mb-12 w-full md:w-8/12 lg:w-5/12 xl:w-5/12 '>
            {error && <AuthAlert errorMessage={error} />}
            {emailSendSuccess && (
              <div className='flex justify-center items-center py-[10px] mb-5 bg-green-200 rounded-md gap-2'>
                <p className='text-[16px] font-poppins font-[400]'>
                  An email has been sent successfully. Please check your inbox.
                </p>
              </div>
            )}
            <form>
              {/* Email input  */}
              <div className='relative mb-4' data-te-input-wrapper-init>
                <h3 className='text-[16px] font-poppins font-[400] leading-[18px] mb-1'>
                  Email*
                </h3>
                <input
                  type='text'
                  value={email}
                  className='peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three'
                  placeholder='Enter your email'
                  onChange={(e) => setEmail(e.target.value)}
                />
                {(email.length > 1 && !isEmailValid()) || initialError.email ? (
                  <div className='flex w-full mb-[15px]'>
                    <p className='text-[red] text-start font-poppins text-[12px] sm:text-[14px]'>
                      *Please enter a valid email
                    </p>
                  </div>
                ) : null}
              </div>

              {/* Password input */}

              <div className='relative mb-6' data-te-input-wrapper-init>
                <h3 className='text-[16px] font-poppins font-[400] leading-[18px] mb-1'>
                  Password*
                </h3>

                <input
                  value={password}
                  type='password'
                  className='peer block min-h-[auto] font-poppins font-[400] w-full rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three'
                  placeholder='Enter password'
                  onChange={(e) => setPassword(e.target.value)}
                />

                {(password.length > 1 && !checkPassword()) ||
                initialError.password ? (
                  <div className='flex w-full mb-[15px]'>
                    <p className='flex flex-wrap text-[red] text-start font-poppins text-[12px] sm:text-[14px]'>
                      *Password must be at least 8 characters long and include
                      at least one uppercase letter, one lowercase letter, one
                      number, and one symbol (!, @, #, $, %, ^, &, *).
                    </p>
                  </div>
                ) : null}
              </div>
              <Link
                to={{
                  pathname: "/forgetpassword",
                  state: { type: "forgetPassword" },
                }}>
                <div className='mb-6 flex items-center justify-end font-poppins font-[400]'>
                  Forgot password?
                </div>
              </Link>

              {/* Login button */}
              <div className={`flex flex-col text-center lg:text-left `}>
                <button
                  type='button'
                  className={` flex justify-center font-poppins font-[400] items-center w-full  rounded bg-theme_three px-7 pb-2.5 pt-3 text-sm uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600`}
                  data-te-ripple-init
                  data-te-ripple-color='light'
                  onClick={() => {
                    checkPassword() && isEmailValid()
                      ? onLogin()
                      : onWrong("login");
                  }}>
                  {loading ? (
                    <Spinner spinnerStyle={"border-white w-5 h-5"} />
                  ) : (
                    "Login"
                  )}
                </button>
                {/* saprater */}
                <div className='my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-black after:mt-0.5 after:flex-1 after:border-t after:border-black'>
                  <p className='mx-4 mb-0 text-center font-semibold dark:text-white'>
                    Or
                  </p>
                </div>

                {/* login with link */}
                <button
                  type='button'
                  className={`flex justify-center font-poppins font-[400] items-center w-full  rounded bg-theme_three px-7 pb-2.5 pt-3 text-sm uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600`}
                  data-te-ripple-init
                  data-te-ripple-color='light'
                  onClick={() => {
                    isEmailValid() ? onLinkLogin() : onWrong("linkLogin");
                  }}>
                  {linkLoading ? (
                    <Spinner spinnerStyle={"border-white w-5 h-5"} />
                  ) : (
                    "Login with link"
                  )}
                </button>
              </div>
              <div>
                {/*  Register link  */}
                <p className='mb-0 mt-5 pt-1 text-[18px] text-center font-poppins font-[400]'>
                  Don't have an account?{" "}
                  <Link
                    to={"/signup"}
                    className='text-theme_three transition duration-150 ease-in-out hover:text-theme_five focus:text-theme_five active:text-theme_five'>
                    Register
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
