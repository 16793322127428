import React from "react";
import { ERROR } from "../../assets/Images";
import { useLocation, useNavigate } from "react-router-dom";

const InvalidLink = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  return (
    <div className="justify-center items-center flex h-screen bg-background">
      <div className="relative w-[320px] my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-center justify-center p-5 border-b border-solid bg-red-200 rounded-t">
            <ERROR className="w-[30px] h-[30px] mx-2" />
            <h3 className="text-xl font-semibold text-center">
              {data?.message ? "In Progress" : "Validation Error"}
            </h3>
          </div>
          {/*body*/}
          <div className="relative p-4 flex-auto">
            <p className="my-4 text-slate-500 text-lg text-center leading-relaxed">
              {data?.message
                ? "Fine-tuning is in progress, please try again later"
                : "Your link is not valid"}
            </p>
          </div>
          {/*footer*/}
          <div className="flex items-center justify-center py-1 border-t border-solid border-slate-200 rounded-b">
            <button
              className="text-red-500 background-transparent font-bold  px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => navigate("/")}
            >
              {data?.message ? "Go to home page" : "Go to login page"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvalidLink;
