/* eslint-disable no-unused-vars */
import axios from "axios";

const BASE_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL; //"http://127.0.0.1:5001/hem-tech/us-central1";
const BASE_URL_PROD = process.env.REACT_APP_BASE_URL_PROD; //"https://us-central1-hem-tech.cloudfunctions.net";

const BASE_URL = BASE_URL_PROD; //BASE_URL_PROD;

export const getChatAnswer = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/getChatAnswer`, data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getImageCreationAnswer = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/getImageCreationAnswer`,
      data
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getImageVariationAnswer = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/getImageVariationAnswer`,
      data
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChatAnswerWithFile = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/getChatAnswerWithFile`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAssistantThread = async () => {
  try {
    const response = await axios.post(`${BASE_URL}/createAssistantThread`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAnswerFromThreads = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/getAnswerFromThreads`, data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAnswerFromFileThreads = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/getAnswerFromFileThreads`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getFineTuneJobRetrieve = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/getFineTuneJobRetrieve`, {
      data,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSpeech = async (data, onChunkFetched) => {
  const { model, voice, text, speed } = data;
  const chunks = splitText(text);
  let currentBlobPromise = fetchAudioChunk(model, voice, chunks[0], speed);

  for (let i = 0; i < chunks.length; i++) {
    const nextChunkIndex = i + 1;
    let nextChunkPromise = null;

    if (nextChunkIndex < chunks.length) {
      nextChunkPromise = fetchAudioChunk(
        model,
        voice,
        chunks[nextChunkIndex],
        speed
      );
    }

    const currentBlob = await currentBlobPromise;

    // Call the callback with the current chunk's response
    if (onChunkFetched) {
      onChunkFetched(currentBlob);
    }

    await playAudioBlob(currentBlob);

    if (nextChunkPromise) {
      currentBlobPromise = nextChunkPromise;
    }
  }
};

async function fetchAudioChunk(model, voice, text, speed) {
  const response = await fetch(`${BASE_URL}/synthesizeSpeech`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ model, voice, text, speed }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.blob();
}

function splitText(text) {
  return text.split(/(?<=\.)/g).filter((chunk) => chunk.trim().length > 0);
}

let currentAudio = null;

async function playAudioBlob(blob) {
  return new Promise((resolve, reject) => {
    stopAudio();
    const audio = new Audio(URL.createObjectURL(blob));
    currentAudio = audio; // Store the reference to the current audio
    audio.play();
    audio.onended = () => {
      currentAudio = null; // Clear the reference when audio ends
      resolve();
    };
    audio.onerror = () => {
      currentAudio = null; // Clear the reference on error
      reject(new Error("Audio playback failed"));
    };
  });
}

export function stopAudio() {
  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0; // Reset the audio to the beginning
    currentAudio = null; // Clear the reference
  }
}
