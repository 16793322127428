import React, { useState } from "react";
import { changeUserPassword } from "../../config/database/authentication";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";
import { FaChevronLeft } from "react-icons/fa";

const ChangePassword = ({
  showChangePasswordModal,
  setShowChangePasswordModal,
  setIsProfileModalOpen,
}) => {
  const email = localStorage.getItem("email");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswordChange = async () => {
    if (currentPassword.length < 8 || newPassword.length < 8) {
      setError(true);
    } else {
      toast.promise(
        async () => {
          await changeUserPassword({
            email,
            currentPassword,
            newPassword,
            setShowChangePasswordModal,
            setErrorMessage,
          });
        },
        {
          loading: "Loading",
          success: "Password change successfully",
          error: "An error occurred while changing password",
        }
      );
    }
  };

  const handleBack = async () => {
    setShowChangePasswordModal(false);
    setIsProfileModalOpen(true);
    setCurrentPassword("");
    setNewPassword("");
    setError(false);
    setErrorMessage("");
  };

  const handleClose = async () => {
    setShowChangePasswordModal(false);
    setCurrentPassword("");
    setNewPassword("");
    setError(false);
    setErrorMessage("");
  };

  return (
    <>
      {showChangePasswordModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl px-4">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                  <button
                    className="absolute left-0 text-neutral-800 background-transparent font-bold uppercase px-8 py-2 text-base md:text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleBack()}
                  >
                    <FaChevronLeft />
                  </button>
                  <h3 className="text-xl md:text-3xl font-semibold text-center">
                    Change Password
                  </h3>
                  <button
                    className="absolute right-0 text-red-500 background-transparent font-bold uppercase px-7 py-2 text-sm md:text-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleClose()}
                  >
                    <ImCross />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-4 flex-auto m-4">
                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <h3 className="text-[16px] font-poppins font-[400] leading-[18px] mb-1">
                      Current Password
                    </h3>

                    <input
                      value={currentPassword}
                      type="password"
                      className="peer block min-h-[auto] font-poppins font-[400] w-full rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three"
                      placeholder="Enter password"
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />

                    {error && currentPassword.length < 8 && (
                      <div className="flex w-full mb-[15px]">
                        <p className="flex flex-wrap text-[red] text-start font-poppins text-[12px] sm:text-[14px]">
                          *Password must be at least 8 characters long
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="relative mb-6" data-te-input-wrapper-init>
                    <h3 className="text-[16px] font-poppins font-[400] leading-[18px] mb-1">
                      New Password
                    </h3>

                    <input
                      value={newPassword}
                      type="password"
                      className="peer block min-h-[auto] font-poppins font-[400] w-full rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three"
                      placeholder="Enter password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />

                    {error && newPassword.length < 8 && (
                      <div className="flex w-full mb-[15px]">
                        <p className="flex flex-wrap text-[red] text-start font-poppins text-[12px] sm:text-[14px]">
                          *Password must be at least 8 characters long
                        </p>
                      </div>
                    )}
                  </div>

                  {errorMessage && (
                    <div className="flex w-full mb-[15px]">
                      <p className="flex flex-wrap text-[red] text-start font-poppins text-[12px] sm:text-[14px]">
                        {errorMessage}
                      </p>
                    </div>
                  )}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                  {/* <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleBack()}
                  >
                    Back
                  </button> */}
                  <div></div>
                  <button
                    disabled={!currentPassword || !newPassword}
                    className={`${
                      currentPassword && newPassword
                        ? "bg-emerald-500 text-white active:bg-emerald-600"
                        : "bg-gray-400 text-gray-700 "
                    } cursor-pointer font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                    type="button"
                    onClick={() => handlePasswordChange()}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ChangePassword;
